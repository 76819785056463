<template>
  <section class="w-100 f6 mt0-l mt4 ph4-l ph3">
    <article class="bg-white mh5-l mh0">
      <div class="ph5-l ph4 pv3">
        <div class="tc">
          <div class="ba b--light-gray bw2">
            <h1 class="f3 f2-ns ttu vidaloka">
              {{ $t("order.confirm_title") }}
            </h1>
          </div>
        </div>

        <article class="karla tracked-light lh-copy">
          <p>{{ $t("order.confirm_article") }}</p>
          <form v-on:submit.prevent="createPayment()">
            <fieldset class="ba b--transparent ph0 mh0">
              <!-- FirstName -->
              <label class="db fw6 lh-copy f6" for="first_name">{{
                $t("order.first_name")
              }}</label>
              <input
                name="first_name"
                id="first_name"
                class="pa2 input-reset ba bg-transparent w-100"
                type="text"
                v-model="firstName"
                placeholder
              />
              <!-- LastName -->
              <label class="db fw6 lh-copy f6" for="last_name">{{
                $t("order.last_name")
              }}</label>
              <input
                name="last_name"
                id="last_name"
                class="pa2 input-reset ba bg-transparent w-100"
                type="text"
                v-model="lastName"
                placeholder
              />

              <!-- Line1 -->
              <label class="db fw6 lh-copy f6" for="line1">{{
                $t("order.line1")
              }}</label>
              <input
                name="line1"
                id="line1"
                class="pa2 input-reset ba bg-transparent w-100"
                type="text"
                v-model="line1"
                placeholder
              />
              <!-- Line2 
							<label class="db fw6 lh-copy f6" for="line2">{{$t("order.line2")}}</label>-->
              <input
                name="line2"
                id="line2"
                class="pa2 input-reset ba bg-transparent w-100"
                type="text"
                v-model="line2"
                placeholder
              />
              <!-- PostalCode -->
              <label class="db fw6 lh-copy f6" for="postal_code">{{
                $t("order.postal_code")
              }}</label>
              <input
                name="postal_code"
                id="postal_code"
                class="pa2 input-reset ba bg-transparent w-100"
                type="text"
                v-model="postalCode"
                placeholder
              />
              <!-- City -->
              <label class="db fw6 lh-copy f6" for="city">{{
                $t("order.city")
              }}</label>
              <input
                name="city"
                id="city"
                class="pa2 input-reset ba bg-transparent w-100"
                type="text"
                v-model="city"
                placeholder
              />
              <!-- Country -->
              <label class="db fw6 lh-copy f6" for="city">{{
                $t("order.country")
              }}</label>
              <div class="dib" style="max-width:90%;">
                <select v-if="countries" v-model="countryCode" class="w-100">
                  <option disabled value>{{
                    $t("order.country_select")
                  }}</option>
                  <option
                    v-for="country in countries"
                    v-bind:value="country.country_code"
                    :key="country.country_code"
                    >{{ country.country }}</option
                  >
                </select>
              </div>
              <!-- Phone number -->
              <label class="db fw6 lh-copy f6" for="phone_number">{{
                $t("order.phone_number")
              }}</label>
              <input
                name="phone_number"
                id="phone_number"
                class="pa2 input-reset ba bg-transparent w-100"
                type="tel"
                v-model="phone_number"
                placeholder
              />
              <!-- Email -->
              <label class="db fw6 lh-copy f6" for="email">{{
                $t("order.email")
              }}</label>
              <input
                :disabled="!isBankTransfer"
                name="email"
                id="email"
                class="pa2 input-reset ba bg-transparent w-100"
                type="email"
                v-model="email"
                placeholder
              />
            </fieldset>

            <button
              class="w-100 f6 fw6 dim dib ttu tracked b white bg-black link tc ph3 pv2 mb2 lh-copy roboto sans-serif"
              :disabled="
                !firstName ||
                  !lastName ||
                  !email ||
                  !postalCode ||
                  !city ||
                  !countryCode ||
                  !postalCode ||
                  !line1 ||
                  loading
              "
            >
              {{ $t("order.confirm") }}
            </button>
          </form>

          <div v-if="loading" class="loading">Loading...</div>

          <div v-if="error" class="dark-red">{{ error }}</div>
        </article>
      </div>
    </article>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "order-save-view",
  methods: {
    createPayment() {
      if (this.isPayPal) {
        this.$store.dispatch("order/createPayPalPayment", {
          language: this.$route.params.lang,
          router: this.$router
        });
      } else if (this.isBankTransfer) {
        this.$store.dispatch("order/createBankTransferPayment", {
          language: this.$route.params.lang,
          router: this.$router
        });
      }
    },
    fetchCountries() {
      this.$store
        .dispatch("country/countries", {
          language: this.$route.params.lang
        })
        .then(() => {
          if (this.countryCode == null || this.countryCode == "") {
            this.countryCode = this.residentCode;
          }
        });
    }
  },
  mounted() {
    this.fetchCountries();
  },
  computed: {
    ...mapGetters("cart", {
      isPayPal: "isPayPal",
      isBankTransfer: "isBankTransfer"
    }),
    ...mapGetters("order", {
      loading: "loading",
      error: "error"
    }),
    ...mapGetters("user", {
      user: "user",
      residentCode: "residentCode"
    }),
    ...mapGetters("country", {
      countries: "countries"
    }),
    firstName: {
      get() {
        if (this.user == null) {
          return null;
        }
        return this.user.first_name;
      },
      set(firstName) {
        this.$store.dispatch("user/update", {
          key: "first_name",
          value: firstName
        });
      }
    },
    lastName: {
      get() {
        return this.user.last_name;
      },
      set(lastName) {
        this.$store.dispatch("user/update", {
          key: "last_name",
          value: lastName
        });
      }
    },
    email: {
      get() {
        return this.user.email;
      },
      set(email) {
        this.$store.dispatch("user/update", { key: "email", value: email });
      }
    },
    countryCode: {
      get() {
        return this.user.shipping_address.country_code;
      },
      set(countryCode) {
        this.$store.dispatch("user/update", {
          key: "country_code",
          value: countryCode
        });
      }
    },
    postalCode: {
      get() {
        return this.user.shipping_address.postal_code;
      },
      set(postalCode) {
        this.$store.dispatch("user/update", {
          key: "postal_code",
          value: postalCode
        });
      }
    },
    city: {
      get() {
        return this.user.shipping_address.city;
      },
      set(city) {
        this.$store.dispatch("user/update", { key: "city", value: city });
      }
    },
    line1: {
      get() {
        return this.user.shipping_address.line1;
      },
      set(line1) {
        this.$store.dispatch("user/update", { key: "line1", value: line1 });
      }
    },
    line2: {
      get() {
        return this.user.shipping_address.line2;
      },
      set(line2) {
        this.$store.dispatch("user/update", { key: "line2", value: line2 });
      }
    },
    phone_number: {
      get() {
        return this.user.phone;
      },
      set(phone) {
        this.$store.dispatch("user/update", { key: "phone", value: phone });
      }
    }
  }
};
</script>

<style lang="stylus"></style>
